.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: "";
    margin: 0 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;

    border-radius: 4px;
    border: 1.5px solid #b8c0cc;
  }

  // Box hover
  &:hover + label:before {
    background: #fd3e60;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: #fd3e60;
    border-radius: 4px;
    border: 1.5px solid #fd3e60;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    right: 13px;
    top: 11px;
    background: white;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}
li {
  margin: 0 0 20px 0 !important;
}
label {
  font-family: "Cairo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  font-feature-settings: "ss04" on;
  color: #000000;
}

.unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin: 20px 0;
}
ul {
  direction: rtl;
}
.centered {
  width: 300px;
  margin: auto;
}

.title {
  text-align: center;
  color: rgb(69, 113, 236);
}
